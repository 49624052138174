// @flow
import * as React from 'react'
import {Link} from 'gatsby'
import {TitledMessage, Button} from '../../../ui-components'
import * as styles from './Support.module.css'

const SUPPORT_DATA = [
  {
    title: 'risk-free return policy',
    message:
      'Return your OT-2 for a full refund within 60 days of purchase. We’ll even pay for shipping.',
  },
  {
    title: 'OT-2 warranty',
    message:
      'If anything on your OT-2 breaks from normal usage within 1 year, we’ll replace it for free. It has been stress-tested to last 3 years of full-time use.',
  },
  {
    title: 'comprehensive support',
    message:
      'Our support team works tirelessly to get you started & avoid downtime.',
  },
]

const IMG_SRC = require('./assets/support-team.png').default
export default function Support() {
  return (
    <section className={styles.support_container}>
      <div className={styles.support_image}>
        <h3>Support when you need it</h3>
        <img src={IMG_SRC} alt="Opentrons support team" />
      </div>
      <div className={styles.support_content}>
        {SUPPORT_DATA.map((m, i) => (
          <TitledMessage
            key={i}
            title={m.title}
            msg={m.message}
            paragraphClassName={styles.support_info}
          />
        ))}
        <Button
          className={styles.support_button}
          Component={Link}
          to="/contact-support"
          gtmAction="click"
          gtmCategory="b-hp"
          gtmLabel="support-contact"
        >
          contact support
        </Button>
      </div>
    </section>
  )
}
