// @flow
import * as React from 'react'
import {Link} from 'gatsby'
import {Button} from '../../../ui-components'

import * as styles from './ModularHardware.module.css'

import {MODULAR_HARDWARE_DATA} from './modular-hardware-data.js'

type Props = {
  imgSrc: string,
  text: string,
  url: string,
}

const buttonProps = {
  className: styles.button,
  children: 'shop all modules',
  Component: Link,
  to: '/modules/temperature-module',
}

export default function ModularHardware() {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h6 className={styles.h6}>Modular Hardware</h6>
        <h3 className={styles.h3}>
          Add hardware as you go, and only buy what you need
        </h3>
      </div>
      <div className={styles.card_container}>
        {MODULAR_HARDWARE_DATA.map((data, i) => {
          return <Card key={i} {...data} />
        })}
      </div>
      <Button {...buttonProps} />
    </div>
  )
}

function Card(props: Props) {
  return (
    <Link className={styles.card} to={props.url}>
      <img className={styles.image} src={props.imgSrc} alt="" />
      <div className={styles.link}>{props.text}</div>
    </Link>
  )
}
