export const AUTOMATE_TEXT = [
  'Serial Dilution',
  'PCR Sample Prep',
  'qPCR Prep',
  'ELISA',
  'NGS Library Prep',
  'Basic Pipetting',
]
export const VIDEO_SRC = 'https://player.vimeo.com/video/260833720'

export const PORTAL_ID = '5383285'
export const FORM_ID = 'e7279336-cfdc-4df4-b122-c309c4cb9f59'

export const BANNER = {
  textShort: '',
  textLong: '',
  links: [],
}
