export const MODULAR_HARDWARE_DATA = [
  {
    imgSrc: require('./assets/thermocycler_01.png').default,
    text: 'Thermocycler Module  ›',
    url: '/modules/thermocycler-module/',
  },
  {
    imgSrc: require('./assets/Temp_319x220@2x-min.jpg').default,
    text: 'Temperature Module  ›',
    url: '/modules/temperature-module/',
  },
  {
    imgSrc: require('./assets/Mag_319x220@2x-min.jpg').default,
    text: 'Magnetic Module  ›',
    url: '/modules/magnetic-module/',
  },
  {
    imgSrc: require('./assets/hepa.jpg').default,
    text: 'HEPA Module  ›',
    url: '/modules/hepa-module/',
  },
  {
    imgSrc: require('./assets/heatershaker_thumbnail.png').default,
    text: 'Heater-Shaker Module  ›',
    url: '/modules/heater-shaker-module/',
  },
]
