// @flow

import * as React from 'react'

import * as styles from './TrustedBy.module.css'

const LOGOS = [
  require('./assets/biobricks.png').default,
  require('./assets/mit.png').default,
  require('./assets/damp-lab.png').default,
  require('./assets/gencove.png').default,
  require('./assets/mayo-clinic.png').default,
  require('./assets/imperial-college-london.png').default,
  require('./assets/stanford.png').default,
]

export default function TrustedBy() {
  return (
    <div className={styles.trusted_section}>
      <h6 className={styles.trusted}>TRUSTED GLOBALLY BY OUR PARTNERS</h6>
      <div className={styles.logo_container}>
        {LOGOS.map((logo, i) => {
          return (
            <div className={styles.logo} key={i}>
              <img src={logo} alt="" />
            </div>
          )
        })}
      </div>
    </div>
  )
}
