// @flow
import * as React from 'react'
import Typed from 'react-typed'

import {Button, LightBox, addGtmTags} from '../../../ui-components'
import {AUTOMATE_TEXT, BANNER, VIDEO_SRC} from './hero-data'
import ScheduleDemoButton from '../../ContactSales/ScheduleDemoButton'

import * as styles from './Hero.module.css'

import video from './assets/hero_video.mp4'

export default function Hero() {
  const [isLightBoxOpen, setIsLightBoxOpen] = React.useState(false)
  const HUBSPOT_CATEGORY = 'b-hp'
  const HUBSPOT_LABEL = 'hero-demo-button'
  /* TODO (mh 2019-11-1): Figure out a way 
  to add event tracking to homepage without 
  ot2 page effect homepage */

  React.useEffect(() => {
    setTimeout(() => {
      addGtmTags(HUBSPOT_CATEGORY, HUBSPOT_LABEL)
    }, 1000)
  }, [])

  return (
    <>
      {BANNER.textShort && BANNER.textLong && (
        <div className={styles.banner}>
          <p className={styles.banner_link}>
            <span className={styles.banner_message_small}>
              {BANNER.textShort}
            </span>
            <span className={styles.banner_message_medium}>
              {BANNER.textLong}
            </span>
            {BANNER.links &&
              BANNER.links.map(function mapLinks(link, index) {
                return (
                  <React.Fragment key={index}>
                    <span className={styles.banner_separator} />
                    <a
                      className={styles.link}
                      href={link.url}
                      data-gtm-category="b-header"
                      data-gtm-label="announcement"
                      data-gtm-action="click"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {link.text}{' '}
                    </a>
                  </React.Fragment>
                )
              })}
          </p>
        </div>
      )}
      <figure className={styles.hero}>
        <video
          className={styles.hero_video}
          autoPlay="autoplay"
          muted="muted"
          loop="loop"
        >
          <source src={video} type="video/mp4" />
        </video>

        <div className={styles.hero_container}>
          <h1 className={styles.h1}>
            Automate <br />
            <Typed strings={AUTOMATE_TEXT} typeSpeed={100} loop />
          </h1>
          <h5 className={styles.h5}>{'Get started for <$10,000'}</h5>
          <div className={styles.hero_button_div}>
            <ScheduleDemoButton className={styles.white_button} />
            <Button
              white
              className={styles.white_button}
              onClick={() => setIsLightBoxOpen(true)}
              gtmCategory="b-hp"
              gtmLabel="hero-intro-button"
              gtmAction="play"
            >
              Play Video
            </Button>
          </div>
        </div>
      </figure>
      {isLightBoxOpen && (
        <LightBox
          videoSrc={VIDEO_SRC}
          onCloseClick={() => setIsLightBoxOpen(false)}
        />
      )}
    </>
  )
}
