import React from 'react'
import {Link} from 'gatsby'
import {Button} from '../../../ui-components'
import Carousel from '../../Carousel'
import ApproachCard from './ApproachCard'
import * as styles from './LabSolution.module.css'

const ButtonProps = {
  className: styles.view_robot,
  Component: Link,
  to: '/ot-2',
  gtmCategory: 'b-hp',
  gtmLabel: 'approach-ot2',
  gtmAction: 'click',
  children: 'view the robot',
}

const MESSAGES = [
  {
    image: 1,
    imgSrc: require('./assets/icon-1.png').default,
    title: 'OPEN-SOURCE',
    strikeoutText: 'PROPRIETARY',
    msg: 'Open-source makes science better. Our apps, protocols, and hardware are publicly available',
  },
  {
    image: 2,
    imgSrc: require('./assets/icon-3.png').default,
    title: 'Access for Large & Small Labs',
    strikeoutText: 'EXPENSIVE',
    msg: 'The OT-2 has a CV comparable to robots 10x the cost',
  },
  {
    image: 3,
    imgSrc: require('./assets/icon-2.png').default,
    title: 'SIMPLE BY NECESSITY',
    strikeoutText: 'COMPLICATED',
    msg: '70% of our customers are new to automation, so our tools are radically simple',
  },
  {
    image: 4,
    title: 'Do It Yourself',
    imgSrc: require('./assets/icon.png').default,
    strikeoutText: 'COMPLEX CUSTOMIZATION',
    msg: `Order it online, just like a laptop. Try it for 60 days and if you don't like it return it-100% risk free`,
  },
]
export default function LabSolution() {
  const CARD = MESSAGES.map((message, i) => {
    return (
      <ApproachCard key={i} image={i} {...message} iconSrc={message.imgSrc} />
    )
  })
  const header = `Lab automation was broken. Here's our solution:`
  return (
    <div className={styles.modern_approach_container}>
      <div className={styles.modern_approach_max_width}>
        <h3 className={styles.h3}>{header}</h3>
        <div className={styles.approach_card_container}>
          <div className={styles.mobile_view}>
            <Carousel node={CARD} />
          </div>
          <div className={styles.desktop_view}>
            {MESSAGES.map((message, i) => {
              return (
                <ApproachCard key={i} {...message} iconSrc={message.imgSrc} />
              )
            })}
          </div>
        </div>
        <div className={styles.button_container}>
          <Button {...ButtonProps} />
        </div>
      </div>
    </div>
  )
}
