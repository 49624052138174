// extracted by mini-css-extract-plugin
export var h3 = "HowToCard-module--h3--VXEzL";
export var h5 = "HowToCard-module--h5--99Cos";
export var card_container = "HowToCard-module--card_container--awMdH";
export var container = "HowToCard-module--container--KNSOn";
export var card_top = "HowToCard-module--card_top--dJSNE";
export var card_bottom = "HowToCard-module--card_bottom--J6Rea";
export var card_section = "HowToCard-module--card_section--T9h+5";
export var card_line = "HowToCard-module--card_line--mtwVB";
export var card_img = "HowToCard-module--card_img--JT2v2";
export var blue = "HowToCard-module--blue--pYZOv";
export var how_to_card_container = "HowToCard-module--how_to_card_container--Gb07F";
export var title = "HowToCard-module--title--XVepy";
export var banner = "HowToCard-module--banner--oNfUB";
export var description = "HowToCard-module--description--DxuuW";
export var button = "HowToCard-module--button--zmZSE";
export var publication = "HowToCard-module--publication--0j1o6";
export var publications_container = "HowToCard-module--publications_container--Nk98M";
export var publications_content = "HowToCard-module--publications_content--WMyiF";
export var publications_heading = "HowToCard-module--publications_heading--Xs795";
export var publications_button = "HowToCard-module--publications_button--sMMRJ";
export var trusted = "HowToCard-module--trusted--STf5N";
export var logo_container = "HowToCard-module--logo_container--EzY2H";
export var logo = "HowToCard-module--logo--SUqAw";
export var trusted_section = "HowToCard-module--trusted_section--tByh8";
export var publication_neg_margin = "HowToCard-module--publication_neg_margin--3oS5H";