export const WORKFLOW_DATA = [
  {
    imgSrc: require('./assets/nac-workstation-283-x-240@2x.png').default,
    text: 'Nucleic Acid Extraction Workstation',
    url: '/workstations/automated-nucleic-acid-extraction/',
    subText:
      'Everything you need to automate size selection bead-based cleanups.',
  },
  {
    imgSrc: require('./assets/ngs-workstation.png').default,
    text: 'NGS Workstation',
    url: '/workstations/expanded-ngs-workstation/',
    subText:
      'Performs nearly end-to-end next generation sequencing (NGS) for a range of genomic and drug discovery applications.',
  },
  {
    imgSrc: require('./assets/nac-workstation-283-x-240@2x.png').default,
    text: 'PCR Workstation',
    url: '/workstations/pcr',
    subText: 'Designed to automate your PCR set up prior to sequencing.',
  },
  {
    imgSrc: require('./assets/nac-workstation-283-x-240@2x.png').default,
    text: 'Magnetic Bead Based Protein Purification/',
    url: '/workstations/protein-purification-bead-based',
    subText:
      'A magnetic bead-based automation for your lysis, sample processing and protein purification.',
  },
  {
    imgSrc: require('./assets/nac-workstation-283-x-240@2x.png').default,
    text: 'Dual Flow Chromatography Protein Purification Workstation',
    url: '/workstations/protein-purification-chromatography/',
    subText:
      'A dual flow chromatography-based automation of your lysis, sample processing and protein purification.',
  },
]
