import React from 'react'
import Page from '../components/Page'
import SEO from '../components/seo'
import HomepagePage from '../components/homepage'

const IndexPage = () => (
  <Page>
    <SEO
      title="Opentrons | Open-source Lab Automation, under $10,000"
      description="Robots for Life Scientists. Labs in 40+ countries trust Opentrons to help automate their workflows. We offer 60-day risk-free returns &amp; flexible payment options."
    />
    <HomepagePage />
  </Page>
)

export default IndexPage
