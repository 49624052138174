// @flow
import * as React from 'react'
import {Link} from 'gatsby'
import cx from 'classnames'
import {Button} from '../../../ui-components'
import * as styles from './HowToCard.module.css'

const ICON_SRC = require('./assets/icon-publication.svg').default

export type PublicationsBannerProps = {
  noBg?: boolean,
}

export default function PublicationsBanner(props: PublicationsBannerProps) {
  const {noBg} = props
  return (
    <div className={!noBg ? styles.publication : null}>
      <section
        className={cx(styles.publications_container, {
          [styles.publication_neg_margin]: noBg,
        })}
      >
        <div className={styles.publications_content}>
          <img src={ICON_SRC} alt="publications" />
          <h3 className={styles.publications_heading}>
            Labs in 40+ countries use the OT-2 to produce - and publish -
            research
          </h3>
          <Button
            Component={Link}
            to="/customers"
            white
            className={styles.publications_button}
          >
            see customers
          </Button>
        </div>
      </section>
    </div>
  )
}
