// @flow

import * as React from 'react'
import {Link} from 'gatsby'

import TrustedBy from '../TrustedBy'

import PublicationsBanner from '../HowTo/PublicationsBanner'

import * as styles from './Workstation.module.css'

import {WORKFLOW_DATA} from './workstation-data'

type Props = {
  imgSrc: string,
  text: string,
  url: string,
  subText: string,
}

export default function Workstation() {
  return (
    <div>
      <div className={styles.container} id="workstation">
        <div className={styles.header}>
          <h6 className={styles.h6}>Workstations</h6>
          <h3 className={styles.h3}>Everything you need in one box</h3>
        </div>
        <div className={styles.card_container}>
          {WORKFLOW_DATA.map((data, i) => {
            return <Card key={i} {...data} />
          })}
        </div>
        <TrustedBy />
      </div>
      <PublicationsBanner noBg={true} />
    </div>
  )
}

function Card(props: Props) {
  const {subText, imgSrc, text, url} = props
  return (
    <div className={styles.card}>
      <img className={styles.image} src={imgSrc} alt="" />
      <div>
        <h5 className={styles.h5}>{text}</h5>
        <p className={styles.p}>{subText}</p>
        <Link className={styles.link} to={url}>
          View now ›
        </Link>
      </div>
    </div>
  )
}
