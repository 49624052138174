// extracted by mini-css-extract-plugin
export var container = "Workstation-module--container--BniA1";
export var link = "Workstation-module--link--wZesU";
export var h3 = "Workstation-module--h3--I8jmk";
export var h5 = "Workstation-module--h5--Zt9yl";
export var h6 = "Workstation-module--h6--Lfguw";
export var p = "Workstation-module--p--wtWI3";
export var header = "Workstation-module--header--0KRsM";
export var card = "Workstation-module--card--Ar127";
export var card_container = "Workstation-module--card_container--uDamg";
export var image = "Workstation-module--image--90kCF";