// extracted by mini-css-extract-plugin
export var modern_approach_container = "LabSolution-module--modern_approach_container--MD+Q7";
export var h3 = "LabSolution-module--h3--XaqXb";
export var h5 = "LabSolution-module--h5--y3GCp";
export var content_div = "LabSolution-module--content_div--rIXEL";
export var approach_card_container = "LabSolution-module--approach_card_container--kW5m3";
export var modern_approach_section = "LabSolution-module--modern_approach_section--EECDn";
export var fadein = "LabSolution-module--fadein--mrGA4";
export var modern_approach_max_width = "LabSolution-module--modern_approach_max_width--S5XT0";
export var button_container = "LabSolution-module--button_container--VTMui";
export var view_robot = "LabSolution-module--view_robot--cinDa";
export var img_div = "LabSolution-module--img_div--HQcwP";
export var trusted = "LabSolution-module--trusted--qneMZ";
export var title_message_p = "LabSolution-module--title_message_p--rxaY5";
export var mobile_view = "LabSolution-module--mobile_view--a+ina";
export var desktop_view = "LabSolution-module--desktop_view--O+xz1";