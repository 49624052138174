import React from 'react'
import {TitledMessage} from '../../../ui-components'
import * as styles from './WhyAutomate.module.css'

import largeImage from './assets/why-automate_642px@2x-min.jpg'
import smallImage from './assets/why-automate_mobile-min.jpg'

export const MESSAGES = [
  {
    title: 'Reproducible Methods',
    msg: 'Easily replicate your results and share optimized protocols with lab automation.',
  },
  {
    title: 'Maximize Time',
    msg: 'Reallocate lab time to data analysis, experiment design, and communicating results—NOT manual pipetting.',
  },
  {
    title: 'Replicate Protocols',
    msg: 'Load & modify customer-verified protocols and let the robot run them, end-to-end. ',
  },
  {
    title: 'Minimize Human Error',
    msg: `Robots don't get tired or distracted. Eliminate this source of error.`,
  },
]

export default function WhyAutomate() {
  return (
    <div>
      <div className={styles.why_automate_container}>
        <div className={styles.content_60}>
          <img className={styles.large_image} src={largeImage} alt="" />
          <img className={styles.small_image} src={smallImage} alt="" />
        </div>
        <div className={styles.content_40}>
          <h3 className={styles.h3}>Why automate</h3>
          {MESSAGES.map((message, i) => {
            return (
              <TitledMessage
                paragraphClassName={styles.text_paragraph}
                key={i}
                title={message.title}
                msg={message.msg}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}
