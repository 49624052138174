// @flow

import React from 'react'
import {TitledMessage} from '../../../ui-components'

import * as styles from './LabSolution.module.css'

type ApproachCardProps = {
  title?: string,
  msg?: string,
  strikeoutText?: string,
  iconSrc?: string,
  messages: Array<ApproachCardProps>,
}

export default function ApproachCard(props: ApproachCardProps) {
  const {title, msg, strikeoutText, iconSrc} = props
  return (
    <div className={styles.modern_approach_section}>
      <div className={styles.img_div}>
        <img src={iconSrc} alt="" />
      </div>
      <div className={styles.content_div}>
        <TitledMessage
          title={title}
          msg={msg}
          strikeoutText={strikeoutText}
          paragraphClassName={styles.title_message_p}
        />
      </div>
    </div>
  )
}
