import React from 'react'
import Hero from './Hero'
import WhyAutomate from './WhyAutomate'
import LabSolution from './LabSolution'
import GetInTouch from '../../components/GetInTouch'
import Support from './Support'
import AutomationContact from './AutomationContact'
import ModularHardware from './ModularHardware'
import Workstation from './Workstation'
import {OurCommunity} from '../OurCommunity'

const HomepagePage = () => (
  <div>
    <Hero />
    <LabSolution />
    <ModularHardware />
    <GetInTouch
      title="Ready for Opentrons in your lab"
      secondaryTitle="Labs in 40+ countries trust Opentrons to help automate their workflows. We offer 60-day risk-free returns &amp; flexible payment options."
      email
      demo
      chat
      gtmCategory={'cta-3'}
      gtmLabelDemo={'schedule-demo'}
      gtmLabelEmail={'email-us'}
      gtmLabelChat={'chat-with-us'}
    />
    <Workstation />
    <Support />
    <OurCommunity bgColor={true} />
    <WhyAutomate />
    <AutomationContact />
  </div>
)

export default HomepagePage
